.lookbook-container {
    max-height: 100vh;
    overflow-y: auto;
    padding: 2rem;
    background-color: #e7e7e7;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    .lookbook-title {
        display: flex;
        justify-content: center;

        h1 {
            font-size: 3rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #333;
            display: inline-block;
            padding-bottom: 0.5rem;
            width: fit-content;
        }
    }

    .lookbook-tag {
        background-color: #d9d9d9;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: bold;
        width: fit-content;
        margin-left: 10px;
    }

    .photo-album {
        margin-bottom: 5rem;
        width: 100%;
    }
}

.back-button {
    margin-bottom: 1rem;
    border: none;
    background-color: transparent;
    align-self: flex-start;
    font-size: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
    color: #000;
}

.back-button:hover {
    font-weight: bold;
}

@media (max-width: 600px) {
    .lookbook-container {
        padding: 1rem;
    }

    .lookbook-title {
        line-height: 1; /* Or adjust as needed */

        h1 {
            margin-top: 0;
            font-size: 2.5rem !important;
        }
    }

    .lookbook-tag {
        align-self: center;
    }
}


.react-photo-album--track {
    display: block !important;
}
